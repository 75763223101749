import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ArtServiceService {


  constructor(private http:HttpClient) { }

  demoapi(fdata: FormData) {
    return this.http.post('http://localhost/Anton-Coding-Solutions-Project-one/Website-Project-One/src/assets/php/artsoftime_api.php', fdata);
  }
}
