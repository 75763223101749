
<!--=====================
        Content
        Photo by <a href="https://unsplash.com/@joe_leahy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Joe Leahy</a> on <a href="https://unsplash.com/@joe_leahy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
  
======================-->
<section id="content"><div class="ic">More Website Templates @ TemplateMonster.com - August11, 2014!</div>
<div class="container">
  <div class="row">
    <div class="grid_12">
      <h2 class="ta__center">Recent  Art</h2>
      <div class="shuffle-group">
        <div class="row">
          <div class="grid_8">
            <div  class="shuffle-me offset__1">
              <a routerLink="/gallery" class="info" target="_blank"></a>
              <div class="images">
                <img src="assets/images/page3_img4.png" alt="">

              </div>
            </div>
          </div>
          <div class="grid_4">
            <div  class="shuffle-me">
              <a routerLink="/gallery" class="info" target="_blank"></a>
              <div class="images">
                <img src="assets/images/page3_img6.png" alt="">

              </div>
            </div>
            <div  class="shuffle-me offset__1">
              <a routerLink="/gallery" class="info" target="_blank"></a>
              <div class="images">
                <img src="assets/images/page3_img10.png" alt="">

              </div>
            </div>
          </div>
          <div class="clear"></div>
          </div>
        </div>
    </div>
  </div>
</div>
<div class="sep-1"></div>
<div class="container">
  <div class="row">
    <div class="grid_8">
      <h3>Bio</h3>
      <img src="assets/images/bio1.jpg" alt="" class="img_inner fleft noresize" width="200" height="200">
      <div class="extra_wrapper"><p class="offset__1">Hi my name is Sandile Selwyn Naphtal Mhkulisi I’m the owner of this company called arts of time I am going to be brief I started doing drawings since little in primary and have developed my skills thought out the growth of my life it then elevated in high school in grade 10,11 and 12 fast forward to 2020 I have revisited my skills by drawing 3 portraits and one a4 picture of the famous Jorja Smith  my goal is to spread my work out there as much as possible and as the company grows I can recruit some fellow artist which will definitely take some time and finance but we keep on pushing for better and for worse. </p></div>
      <div class="clear"></div>

      <a routerLink="/about" class="btn">more</a> 
    </div>
    <div class="grid_4">
      <h3>Follow me</h3>
      <ul class="socials">
        <li>
          <div class="fa fa-facebook"></div>
          <a href="https://www.facebook.com/sandile.selwyn5">Be a fan on Facebook</a>
        </li>
        <li>
          <div class="fa fa-instagram"></div>
          <a href="https://www.instagram.com/selwyn_she_wrote/">Follow me on Instagram</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="sep-1"></div>
</section>