import { Component, OnInit } from '@angular/core';
import { ArtServiceService } from '../art-service.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  name: String = "";
  email: String = "";
  phone: String = "";
  message: String = "";

  response: String = "";

  constructor(private us: ArtServiceService) { }
  saveapi(val){

    console.log(val);
    
    this.email = val.email;
    this.name = val.name;
    this.phone = val.phone;
    this.message = val.message; 

    var fd = new FormData();
    
    fd.append("name", val.name);
    fd.append("email", val.email);
    fd.append("phone", val.phone);
    fd.append("message", val.message);

    this.us.demoapi(fd).subscribe((response) => {
      response = response;
    });
  }

  ngOnInit () : void {

  }
}
