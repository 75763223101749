<!--=====================
          Content
          Image : Photo by <a href="https://unsplash.com/@vlisidis?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Terry Vlisidis</a> on <a href="https://unsplash.com/s/photos/dark?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
  
======================-->
<section id="content"><div class="ic">More Website Templates @ TemplateMonster.com - August11, 2014!</div>
    <div class="container">
      <div class="row">
        <div class="grid_12">
          <h2>My Biography and Experience</h2>
            <img src="assets/images/page2_img1.png" alt="" class="img_inner fleft" width="300" height="300">
            <p>  
              Hello my name is Sandile Selwyn Naphtal Mkhulisi I’m the creator of my artistic solo business called Arts of time so here is a brief background of myself I was born in Durban Kwazulu natal was raised for a short period of time in Joburg then moved to Ermelo Mpumalanga till I moved back to Durban around 2011 entered a high school called croosmoor secondary where in grade 10 where I chose arts as 1 of my three subjects amongst that subject were EGD and civil technology.             
            </p>
            <p>
              My favourite subject was arts as much as it took time to perfect my shading ability yet in the end I was able to shade after matriculating I entered a university called DUT(Durban University of Technology) For interior design in 2015 and I didn’t complete the subject from around 2018 cause of lack of passion for the subject then I moved to Joburg late 2018 till now so from 2020 I started to realize I still have the artistic talent I had back in high school so I started to practice again to regain the talent until I went to buy the artistic materials.            
            </p>
            <p>
              I started with an A4 page and portrait of Jorje Smith that went well then I started with the big ones by big I basically mean the A3s ha ha anyway I only managed to make 3 artworks then I took a long break from making art till this year!             
            </p>
            <img src="assets/images/page2_img2.jpg" alt="" class="img_inner img_fright" width="200" height="200">
            <p>
              Reason why I resumed was by my cousins putting a little bit of pressure on me to do it again until one of my older cousins saw my art and offered to pay and create websites for my company and so forth so that motivated me even more to return! 
            </p>
            <p>
              So my objective as an artist is to make as many of artworks and obviously make a profit out of my upcoming hard work and make sure that arts of time becomes big as it can be the pressure will be a lot but that wont stop me not one bit so as for my characteristics 
            </p>
            <p>

              I’m a person who socialize when the next person can socialize other than that I’m a bit of an introvert yet like I said I can socialize when spoken to. I used to work back in 2016 in a retail store called sports scene for 3 months only and I also have a driver’s license so I am well decorated one of my other hobbies I have besides drawing is music producing I started practising in 2016 and perfected it the following year in 2017 and yeah that’s about all I have to say about myself and hope to serve you my future clients well in the future with my artistic talents goodbye.
            </p>
        </div>
      </div>
    </div>
    <div class="sep-1 offset__1"></div>
    <div class="container">
      <div class="row">
        <!--<div class="grid_12">
          <h3>My Recent  Awards</h3>
        </div>
        <div class="grid_3">
          <div class="block-1">
            <div class="fa fa-image"></div>
            <div class="block-1_title">Aliquam nibh ante</div>Dorem ipsum dolor sit amet, consectetur adipiscing elit. In mollis erat mattis neque facilisis, sit amet ultricies erat rutrum. Cras facilisis, nulla vel viverra auctor, leo magna sodales felis, quis malesuada nibh odio ut velit
            <br>
            <a href="#" class="btn">more</a>
          </div>
        </div>
        <div class="grid_3">
          <div class="block-1">
            <div class="fa fa-trophy"></div>
            <div class="block-1_title">Kiam nibh anteli</div>Dorem ipsum dolor sit amet, consectetur adipiscing elit. In mollis erat mattis neque facilisis, sit amet ultricies erat rutrum. Cras facilisis, nulla vel viverra auctor, leo magna sodales felis, quis malesuada nibh odio ut velit
            <br>
            <a href="#" class="btn">more</a>
          </div>
        </div>
        <div class="grid_3">
          <div class="block-1">
            <div class="fa fa-star"></div>
            <div class="block-1_title">Nomilonibh anter</div>Dorem ipsum dolor sit amet, consectetur adipiscing elit. In mollis erat mattis neque facilisis, sit amet ultricies erat rutrum. Cras facilisis, nulla vel viverra auctor, leo magna sodales felis, quis malesuada nibh odio ut velit
            <br>
            <a href="#" class="btn">more</a>
          </div>
        </div>
        <div class="grid_3">
          <div class="block-1">
            <div class="fa fa-camera"></div>
            <div class="block-1_title">Moliuam nibh anteg</div>Dorem ipsum dolor sit amet, consectetur adipiscing elit. In mollis erat mattis neque facilisis, sit amet ultricies erat rutrum. Cras facilisis, nulla vel viverra auctor, leo magna sodales felis, quis malesuada nibh odio ut velit
            <br>
            <a href="#" class="btn">more</a>
          </div>
        </div>-->
      </div>
    </div>
  </section>