<!--==============================
              header
=================================-->
<header>
    <div class="header_top">
      <div class="container">
        <div class="row">
          <div class="grid_12">
            <h1><a href="#">artsoftime <br> Feel the beauty of art </a></h1>
            Imagine what we'll create
          </div>
        </div>
      </div>
    </div>
    <section id="stuck_container">
    <!--==============================
                Stuck menu
    =================================-->
      <div class="container">
        <div class="row">
          <div class="grid_12 ">
            <h1 class="logo">
              <a routerLink="/home">
                artsoftime
              </a>
            </h1>
            <div class="navigation ">
              <nav>
                <ul class="sf-menu">
                 <li><a routerLink="/home">Home</a></li>
                 <li><a routerLink="/about">About</a></li>
                 <li><a routerLink="/gallery">Gallery</a></li>
                 <li><a routerLink="/contact">Contacts</a></li>
               </ul>
              </nav>
              <div class="clear"></div>
            </div>       
           <div class="clear"></div>  
          </div>
       </div> 
      </div> 
    </section>
  </header>
