<!--==============================
              footer
=================================-->
<footer id="footer">
    <div class="container">
      <div class="row">
        <div class="grid_12"> 
          <h2>Contacts</h2>
          <div class="footer_phone">+27 (67) 057 7837</div>
          <a routerLink="/contact" class="footer_mail">sandilem@artsoftime.com</a>
          <div class="sub-copy">Copyright &copy; 2021 Anton Coding Solutions. designed by <a href="http://www.templatemonster.com/" rel="nofollow">TemplateMonster.com</a></div>
        </div>
      </div>
  
    </div>  
  </footer>
  <a href="#" id="toTop" class="fa fa-chevron-up"></a>
