<!--=====================
          Content
======================-->
<section id="content" class="gallery"><div class="ic">More Website Templates @ TemplateMonster.com - August11, 2014!</div>
    <div class="container">
      <div class="row">
        <div class="grid_12">
          <h2>Artsoftime Gallery</h2>
        </div>
        <div class="grid_4">
          <div class="box">
            <a href="assets/images/page3_img4.png" class="gall_item"><img src="assets/images/page3_img4.png" alt=""><span></span></a>
            <div class="box_bot">
              <div class="box_bot_title">Fire and ice</div>
              <p>
                  THE PURPOSE: This was my first artwork after years of making one I made this one last year in 2020 so I used both models one whom I forgot the name of yet I follow on Instagram the other a female I know named Aroha (the blue figured female) I wanted the theme to be an elemental type of work where I played with fire and ice and their attire were opposite the fire skin would have an icy attire and the ice skinned model would wear fire based attire basically I only made this to prove that opposites an attract.                 
              </p>
            </div>
          </div>
        </div>
        <div class="grid_4">
          <div class="box">
            <a href="assets/images/page3_img5.png" class="gall_item"><img src="assets/images/page3_img5.png" alt=""><span></span></a>
            <div class="box_bot">
              <div class="box_bot_title">Death by the Elements</div>
              <p>
                THE PURPOSE: One of the best artworks I had made back in my high school days so right to the purpose of it well the theme and idea came from our visual arts teacher Mr. T Govendor.</p>
            </div>
          </div>
        </div>
        <div class="grid_4">
          <div class="box">
            <a href="assets/images/page3_img6.png" class="gall_item"><img src="assets/images/page3_img6.png" alt=""><span></span></a>
            <div class="box_bot">
              <div class="box_bot_title">Mood swings</div>
              <p>
                cally with art with mood swings the name of the title of the art work composition was nessecary when I saw these two models it was a perfect match for me to use and apply in my canvas im really proud of the hair especially on the green model abstract color was easy to use cause at the time I had to use what I had and just murder it one time and its finishing touch.                            
              </p>
            </div>
          </div>
        </div>
        <div class="grid_4">
          <div class="box">
            <a href="assets/images/page4_img7.png" class="gall_item"><img src="assets/images/page3_img7.png" alt=""><span></span></a>
            <div class="box_bot">
              <div class="box_bot_title">Northern Nostalgia</div>
              <p>
                Purpose: I remember this being my artistic break-through in terms of my art talent being good now the background is the main reason why I called it as it’s called northern nostalgia is because of the town I grew up in as a child which is called Ermelo a lot of memories where cherished over there from primary to high school and the in between of my social life and the color on the background regarding more especially the sky and its color the sunset view.           
              </p>
              </div>
          </div>
        </div>

        <div class="grid_4">
          <div class="box">
            <a href="assets/images/page4_img8.png" class="gall_item"><img src="assets/images/page3_img8.png" alt=""><span></span></a>
            <div class="box_bot">
              <div class="box_bot_title">THE ELEMENTS</div>
              <p>
                Purpose: An art work most people whom have seen the artwork were amazed by I worked real hard on this one especially with its background I made it more for my team aka friends of whom we call ourselves Destructive Elements I love movies especially their posters so I had to make one for my team and I and it went brilliantly the artwork is like a comedy film mainly because of how funny my friends are.              </p>
            </div>
          </div>
        </div>

        <div class="grid_4">
          <div class="box">
            <a href="assets/images/page3_img9.png" class="gall_item"><img src="assets/images/page3_img9.png" alt=""><span></span></a>
            <div class="box_bot">
              <div class="box_bot_title">Selwyn vs Sandile</div>
              <p>
                Purpose: The first of the four artworks I had made in 2014 I had no idea at all of what to do nor how to top the last artwork I had made last year yet I pulled through and browsed through my photo gallery as to what to draw and I had found 3 pictures which matched perfectly.              
              </p>
            </div>
          </div>
        </div>

        <div class="grid_4">
          <div class="box">
            <a href="assets/images/page4_img10.png" class="gall_item"><img src="assets/images/page3_img10.png" alt=""><span></span></a>
            <div class="box_bot">
              <div class="box_bot_title">Hoiqum nibh antetew</div>
              <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit. In mollis erat mattis neque facilisis, sit amet ultricies erat rutrum. Cras facilisis, nulla vel viverra auctor, leo magna sodales felis, quis malesuada nibh odio ut velit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>