<!--=====================
          Content
======================-->
<section id="content"><div class="ic">More Website Templates @ TemplateMonster.com - August11, 2014!</div>
    <div class="container">
      <div class="row">
        <div class="grid_8">
          <h2>Blog</h2>
          <div class="blog">
            <img src="images/page4_img1.jpg" alt="" class="img_inner">
            <div class="text1 color1"><a href="#">Aliquam nibh ante dertolinom sertowy munot desto</a></div>
            <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit. In mollis erat mattis neque facilisis, sit amet ultricies erat rutrum. Cras facilisis, nulla vel viverra auctor, leo magna sodales felis, quis malesuada nibh odio ut velit.</p>
            Proin pharetra luctus diam, a scelerisque eros convallis accumsan. Maecenas vehicula egestas venenatis. Duis massa eliterto dertolo auctor non pellentesque vel, aliquet sit amet erat. In mollis erat mattis neque facilisis, sit amet ultricies erat rutrum. Cras facilisis, nulla vel viverra auctor, leo magna sodales felis, quis malesuada nibh odio ut veliter.
            <br>
            <a href="#" class="btn">more</a>
            <table>
            <tbody>
              <tr>
                <td><time datetime="2014-01-01">
                  <span class="fa fa-calendar"></span>
                  January 20, 2014   
                </time> </td>
                <td><a href="#"><div class="fa fa-user"></div> Admin</a></td>
                <td><a href="#"><span class="fa fa-link"></span> Permalink</a></td>
              </tr>
              <tr>
                <td><div class="fa fa-bookmark"></div> Uncategorized  </td>
                <td colspan="2"><div class="fa fa-tag"></div> No tags</td>
              </tr>
              <tr>
                <td><div class="fa fa-comments"></div> No commentts   </td>
                <td colspan="2"><div class="fa fa-eye"></div>182
                <div class="fa fa-thumbs-up"></div>0
                <div class="fa fa-thumbs-down"></div>0</td>
              </tr>
            </tbody>
          </table>
          </div>
          <div class="blog">
            <img src="images/page4_img2.jpg" alt="" class="img_inner">
            <div class="text1 color1"><a href="#">Koquam nibh ante dertolinom sertowy munotoer</a></div>
            <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit. In mollis erat mattis neque facilisis, sit amet ultricies erat rutrum. Cras facilisis, nulla vel viverra auctor, leo magna sodales felis, quis malesuada nibh odio ut velit.</p>
            Proin pharetra luctus diam, a scelerisque eros convallis accumsan. Maecenas vehicula egestas venenatis. Duis massa eliterto dertolo auctor non pellentesque vel, aliquet sit amet erat. In mollis erat mattis neque facilisis, sit amet ultricies erat rutrum. Cras facilisis, nulla vel viverra auctor, leo magna sodales felis, quis malesuada nibh odio ut veliter.
            <br>
            <a href="#" class="btn">more</a>
            <table>
            <tbody>
              <tr>
                <td><time datetime="2014-01-01">
                  <span class="fa fa-calendar"></span>
                  January 20, 2014   
                </time> </td>
                <td><a href="#"><div class="fa fa-user"></div> Admin</a></td>
                <td><a href="#"><span class="fa fa-link"></span> Permalink</a></td>
              </tr>
              <tr>
                <td><div class="fa fa-bookmark"></div> Uncategorized  </td>
                <td colspan="2"><div class="fa fa-tag"></div> No tags</td>
              </tr>
              <tr>
                <td><div class="fa fa-comments"></div> No commentts   </td>
                <td colspan="2"><div class="fa fa-eye"></div>182
                <div class="fa fa-thumbs-up"></div>0
                <div class="fa fa-thumbs-down"></div>0</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
        <div class="grid_4">
          <h2>Categories</h2>
          <ul class="list-1">
            <li><span></span><a href="#"><div class="fa fa-chevron-right"></div>Waecenas vehicuas </a></li>
            <li><span></span><a href="#"><div class="fa fa-chevron-right"></div>Kaecenas vehicula egs </a></li>
            <li><span></span><a href="#"><div class="fa fa-chevron-right"></div>Daecenas vehicula egesta </a></li>
            <li><span></span><a href="#"><div class="fa fa-chevron-right"></div>Faecenas vehicula egestte </a></li>
            <li><span></span><a href="#"><div class="fa fa-chevron-right"></div>Faecenas vehicula egestso </a></li>
            <li><span></span><a href="#"><div class="fa fa-chevron-right"></div>Faecenas vehicula egestas </a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>