<!--=====================
          Content
======================-->
<section id="content"><div class="ic">More Website Templates @ TemplateMonster.com - August11, 2014!</div>
  <div class="container">
    <div class="row">
      <div class="grid_12">
        <h2>Contacts</h2>
        <div class="map">
          <figure class="">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d24214.807650104907!2d-73.94846048422478!3d40.65521573400813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1395650655094" style="border:0"></iframe>
          </figure>
        </div>
      </div>


      <div class="grid_6 preffix_1">
        <h2>Contact  Form</h2>
          <form id="contact-form"  action="contact.component.html" method="POST" #frm="ngForm" (ngSubmit)="saveapi(frm.value)">
                  <div class="contact-form-loader"></div>
                  <fieldset>
                    <label class="name">
                      <input name="name" type="text" placeholder="Name:" class="form-control" data-constraints="@Required @JustLetters" autorequired ngModel>
                      <span class="empty-message">*This field is required.</span>
                      <span class="error-message">*This is not a valid name.</span>
                    </label>
                   
                    <label class="email">
                      <input name="email" type="text" placeholder="E-mail:" class="form-control" data-constraints="@Required @Email" autorequired ngModel>
                      <span class="empty-message">*This field is required.</span>
                      <span class="error-message">*This is not a valid email.</span>
                    </label>

                    <label class="phone">
                      <input name="phone" type="text" placeholder="Phone:" class="form-control" data-constraints="@Required @JustNumbers" autorequired ngModel>
                      <span class="empty-message">*This field is required.</span>
                      <span class="error-message">*This is not a valid phone.</span>
                    </label>
                   
                    <label class="message">
                      <textarea name="message" type="text" placeholder="Message:" class="form-control" data-constraints='@Required @Length(min=20,max=999999)' autorequired ngModel></textarea>
                      <span class="empty-message">*This field is required.</span>
                      <span class="error-message">*The message is too short.</span>
                    </label>

                    <div class="ta__right">
                      <a routerLink="/contact" class="btn" data-type="reset">clear</a>
                      <button type="submit" name="submit" class="btn_send" ngModel>send</button>
                    </div>

                  </fieldset> 
                  <div class="modal fade response-message">
                    <div class="modal-dialog">
                      <div class="modal-content">

                        <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                          <h4 class="modal-title">Modal title</h4>
                        </div>

                        <div class="modal-body">
                          You message has been sent! We will be in touch soon.
                        </div>   

                      </div>
                    </div>
                  </div>
                </form>   
      </div>


      <div class="grid_5">
        <h2>Contact Info</h2>
        <div class="text1 tx__1">   
          If any of you guys would like an artwork done by me I’d be delighted so, please don’t be afraid to contact me in any of these contact sources below I’ll be in contact as soon as possible.
      </div>

        The artsoftime Inc. <br>
        3525 Mbhongisa street,<br>
        Zone 3 Pimville.<br>
        Phone: +27 (67) 057 7837<br>
        E-mail: <a routerLink="/contact">
          sandilem@artsoftime.com
        </a>
      </div>

    </div>
  </div>

</section>