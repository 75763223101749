import { Component, OnInit } from '@angular/core';
import { ArtServiceService } from './art-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'artsoftime';

  constructor(private service: ArtServiceService) {}
  ngOnInit(){
//this.getDataFromAPI();
  }

  /*getDataFromAPI(){
    this.service.getData().subscribe((response) => {
      console.log('Response to Api is ', response);
    }, (error) => {
      console.log('Error is ', console.error());
    });
  }*/
}
